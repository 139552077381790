
  
  table {
    display: block;
  }
  
  tr, td, tbody, tfoot {
    display: block;
  }
  
  thead {
    display: none;
  }
  
  tr {
    padding-bottom: 10px;
  }
  
  td {
    padding: 10px 10px 0;
    text-align: center;
  }

  
  table {
    width: 100%;
  }
  
  th {
    text-align: left;
    font-weight: 700;
  }
  
  thead th {
    background-color: #202932;
    color: #fff;
    border: 1px solid #202932;
  }
  
  tfoot th {
    display: block;
    padding: 10px;
    text-align: center;
    color: #b8c4d2;
  }
  

  
  .select {
    padding-bottom: 20px;
    border-bottom: 1px solid #28333f;
  }
  .select:before {
    display: none;
  }


  
  dl {
    margin: 0;
    padding: 0;
  }
  
  dt {
    font-size: 2.2rem;
    font-weight: 300;
  }
  
  dd {
    margin: 0 0 40px 0;
    font-size: 1.8rem;
    padding-bottom: 5px;
    border-bottom: 1px solid #ac2647;
    box-shadow: 0 1px 0 #c52c51;
  }

  
  @media (min-width: 460px) {
    td {
      text-align: left;
    }
    td:before {
      display: inline-block;
      text-align: right;
      width: 140px;
    }
  
    .select {
      padding-left: 160px;
    }
  }
  @media (min-width: 720px) {
    table {
      display: table;
    }
  
    tr {
      display: table-row;
    }
  
    td, th {
      display: table-cell;
    }
  
    tbody {
      display: table-row-group;
    }
  
    thead {
      display: table-header-group;
    }
  
    tfoot {
      display: table-footer-group;
    }
  
    td {
      border: 1px solid #28333f;
    }
    td:before {
      display: none;
    }
  
    td, th {
      padding: 10px;
    }

  
    tfoot th {
      display: table-cell;
    }
  
    .select {
      padding: 10px;
    }
  }

  .buttonsize{
    width:529px;
    max-width: 100%;
  }
  .moveupv2{
    margin-top: -5%; /* for relative positioning */
  }