:root {
  --color-default: #0a0d13;
  --color-primary: #0d42ff;
  --color-secondary: #0e1d34;
}
body {
  margin: 0;
  padding: 0;
  font-family: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  background-color: #191B1F;

  
}
/* Navigation bar styles  */
.nav {
  text-align: center;
  font-size: 1rem;
  border-bottom: 1px solid hsla(0,0%,100%,0.1);
  padding-left: 5px;
  padding-right: 5px;
  background-color: #191B1F;
  margin-top: 20px;
}

.nav-link {
  text-decoration: none;
  display: inline-block;
  padding: 13px 13px;
  color: #fff;
  margin-right: 5px;
}

.nav-link:hover {
  text-decoration:none;
  color: #343a40;
}
.nav-link.current {
  border-bottom: 2px solid #38d9a9;
}
.btn {
  padding: 10px 20px;
  font-size: 1rem;
}
@media (min-width: 768px) {
  .nav {
    font-size: 1.1rem;
    padding-left: 10px;
    padding-right: 10px;
  }
  .nav-link {
    padding: 20px 20px;
  }

}
.moveup{
  margin-top: -4%; /* for relative positioning */
}
/* End of Navigation bar styles  */
